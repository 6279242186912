import { put, call, takeLatest } from 'redux-saga/effects';

import { SERVER_URL } from 'utils/environment';
import { get } from 'utils/request';
import { getLanguagesSuccess, getLanguagesError } from './actions';
import { GET_LANGUAGES } from './constants';

function* getLanguages({ locale }) {
  const requestURL = `${SERVER_URL}/api/languages${
    locale ? `?locale=${locale}` : ''
  }`;
  try {
    const response = yield call(get, requestURL);
    yield put(getLanguagesSuccess(response));
  } catch (error) {
    yield put(getLanguagesError(error));
  }
}
export default function* languageSaga() {
  yield takeLatest(GET_LANGUAGES, getLanguages);
}
