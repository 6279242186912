/* eslint-disable no-debugger */
import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { API_URL, API_URL_NO_VERSION } from 'utils/environment';
import { post, put, get } from 'utils/request';
import { toggleFreeSession } from 'containers/UserInformation/actions';
import {
  createMatchSuccess,
  createMatchError,
  updateMatchSuccess,
  updateMatchError,
  fetchCurrentMatchSuccess,
  fetchCurrentMatchError,
  fetchMatchResultsSuccess,
  fetchMatchResultsError,
  fetchPaginatedMatchResultsSuccess,
  fetchPaginatedMatchResultsError,
  fetchMatchReportSuccess,
  fetchMatchReportError,
  verifyPhoneNumberSuccess,
  verifyPhoneNumberError,
  createPhoneNumberSuccess,
  createPhoneNumberError,
  fetchDirectMatchSuccess,
  fetchDirectMatchError,
  confirmPractitionerMatchSuccess,
  confirmPractitionerMatchError,
  fetchPractitionerSuccess,
  fetchPractitionerError,
  createMessageSuccess,
  createMessageError,
  continueFromSourceSuccess,
  continueFromSourceError,
  auditProgressSuccess,
  auditProgressError,
  checkCoughlinEmailError,
  checkCoughlinEmailSuccess,
  fetchOrsScoreSuccess,
  fetchOrsScoreError,
  createGuardiansSuccess,
  createGuardiansError,
  fetchGuardiansSuccess,
  fetchGuardiansError,
  fetchSpecialitiesSuccess,
  fetchDiagnosisSuccess,
} from './actions';
import {
  CREATE_MATCH,
  UPDATE_MATCH,
  FETCH_CURRENT_MATCH,
  FETCH_MATCH_RESULTS,
  FETCH_MATCH_REPORT,
  VERIFY_PHONE_NUMBER,
  CREATE_PHONE_NUMBER,
  FETCH_DIRECT_MATCH,
  CONFIRM_PRACTITIONER_MATCH,
  FETCH_PRACTITIONER,
  CREATE_MESSAGE,
  CONTINUE_FROM_SOURCE,
  AUDIT_PROGRESS,
  CHECK_COUGHLIN_EMAIL,
  FETCH_ORS_SCORE,
  INVITE_PARTNER,
  CREATE_GUARDIANS,
  FETCH_GUARDIANS,
  FETCH_PAGINATED_MATCH_RESULTS,
  FETCH_SPECIALITIES,
  FETCH_DIAGNOSIS,
} from './constants';

function* createMatch({ matchType, providerType, callback }) {
  const params = { match_type: matchType, provider_type: providerType };
  const requestURL = `${API_URL}/matches`;

  try {
    const match = yield call(post, requestURL, params);
    yield reduxPut(createMatchSuccess(match));
    if (callback) yield call(callback, match);
  } catch (error) {
    yield reduxPut(createMatchError(error));
  }
}

function* fetchCurrentMatch({ providerType }) {
  let requestURL = `${API_URL}/matches`;
  if (providerType) {
    requestURL = `${requestURL}?provider_type=${providerType}`;
  }

  try {
    const match = yield call(get, requestURL);
    yield reduxPut(fetchCurrentMatchSuccess(match));
  } catch (error) {
    yield reduxPut(fetchCurrentMatchError(error));
  }
}

function* updateMatch({ id, questionCode, answers, other_answer, callback }) {
  const params = {
    answers,
    other_answer,
    question_code: questionCode,
  };
  const requestURL = `${API_URL}/matches/${id}`;

  try {
    const match = yield call(put, requestURL, params);
    yield reduxPut(updateMatchSuccess(match));
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(updateMatchError(error));
  }
}

function* fetchMatchResults({ values, callback }) {
  const requestURL = `${API_URL}/matches/results?id=${values.id}&out_of_network=${values.outOfNetwork}&sort_by=${values.sortBy}${values.modality}`;
  const { distanceCacheType } = values;
  const cachedResults = JSON.parse(
    localStorage.getItem(values.distanceCacheType),
  );

  if (distanceCacheType && cachedResults && cachedResults.length >= 3) {
    const slicedCache = cachedResults.slice(0, 3);
    yield reduxPut(fetchMatchResultsSuccess(slicedCache));
    if (callback) callback(slicedCache);
  } else {
    try {
      const results = yield call(get, requestURL);
      yield reduxPut(fetchMatchResultsSuccess(results));
      if (distanceCacheType) {
        const resultsJSON = JSON.stringify(results);
        localStorage.setItem(distanceCacheType, resultsJSON);
      }
      if (callback) callback(results);
    } catch (error) {
      yield reduxPut(fetchMatchResultsError(error));
    }
  }
}

function* fetchPaginatedMatchResults({ values }) {
  const { id, page, distanceCacheType, outOfNetwork, sortBy, modality } =
    values;
  const requestURL = `${API_URL}/matches/results_paginated?id=${id}&page=${page}&out_of_network=${outOfNetwork}&sort_by=${sortBy}${modality}`;

  const cachedResults = JSON.parse(
    localStorage.getItem(values.distanceCacheType),
  );
  const resultsToShow = page * 3;
  if (
    distanceCacheType &&
    cachedResults &&
    cachedResults.length >= resultsToShow
  ) {
    const firstIdx = (page - 1) * 3;
    const lastIdx = firstIdx + 3;
    yield reduxPut(
      fetchPaginatedMatchResultsSuccess(cachedResults.slice(firstIdx, lastIdx)),
    );
  } else {
    try {
      const results = yield call(get, requestURL);
      yield reduxPut(fetchPaginatedMatchResultsSuccess(results));
      if (distanceCacheType) {
        const addedCache = cachedResults.concat(results);
        const addedCacheJSON = JSON.stringify(addedCache);
        localStorage.setItem(distanceCacheType, addedCacheJSON);
      }
    } catch (error) {
      yield reduxPut(fetchPaginatedMatchResultsError(error));
    }
  }
}

function* fetchMatchReport({ values, callback }) {
  const requestURL = `${API_URL}/matches/match_report?id=${values.id}`;

  try {
    const results = yield call(get, requestURL);
    yield reduxPut(fetchMatchReportSuccess(results));
    if (callback) callback(results);
  } catch (error) {
    yield reduxPut(fetchMatchReportError(error));
  }
}

function* createPhoneNumber({ values, callback, errorCallback }) {
  const params = {
    country_code: values.country,
    number: values.number,
    call_to: values.call_to,
    phone_type: 'primary',
  };
  const requestURL = `${API_URL}/phone_numbers`;

  try {
    const phoneNumber = yield call(post, requestURL, params);
    yield reduxPut(createPhoneNumberSuccess(phoneNumber));
    if (callback) callback(phoneNumber);
  } catch (error) {
    yield reduxPut(createPhoneNumberError(error));
    if (errorCallback) errorCallback(error);
  }
}

function* verifyPhoneNumber(values) {
  const params = {
    code: values.code,
    phone_type: 'primary',
  };
  const requestURL = `${API_URL}/phone_numbers/verify?code=${values.code}&phone_type=primary`;
  try {
    const phoneNumberVerify = yield call(get, requestURL, params);
    yield reduxPut(verifyPhoneNumberSuccess(phoneNumberVerify));
    yield call(values.callback);
  } catch (error) {
    yield reduxPut(verifyPhoneNumberError(error));
    yield call(values.errorCallback);
  }
}

function* fetchDirectMatch(values) {
  const requestURL = `${API_URL}/referrals?code=${values.code}`;

  try {
    const match = yield call(get, requestURL);
    yield reduxPut(fetchDirectMatchSuccess(match));
  } catch (error) {
    yield reduxPut(fetchDirectMatchError(error));
  }
}

function* confirmPractitionerMatch(values) {
  const requestURL = `${API_URL}/matches/confirm?id=${values.id}&practitioner_id=${values.practitionerId}`;

  try {
    const data = yield call(get, requestURL);
    const success = yield reduxPut(confirmPractitionerMatchSuccess());
    if (success) {
      yield reduxPut(toggleFreeSession(data.has_a_free_session));
      yield call(values.callback);
    }
  } catch (error) {
    yield reduxPut(confirmPractitionerMatchError(error));
  }
}

function* fetchPractitioner(values) {
  const requestURL = `${API_URL}/practitioners/${values.id}`;

  try {
    const practitioner = yield call(get, requestURL);
    yield reduxPut(fetchPractitionerSuccess(practitioner));
  } catch (error) {
    yield reduxPut(fetchPractitionerError(error));
  }
}

function* createMessage({
  chatId,
  chatMessage,
  sensitiveYn,
  noAvailabilityYn,
  callback,
}) {
  const params = {
    id: chatId,
    message: chatMessage,
    sensitive_yn: sensitiveYn,
    no_availability_yn: noAvailabilityYn,
  };
  const requestURL = `${API_URL}/chats/message`;

  try {
    const message = yield call(post, requestURL, params);
    yield reduxPut(createMessageSuccess(message));
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(createMessageError(error));
  }
}

function* continueFromSource({ id }) {
  const requestURL = `${API_URL}/matches/continue_from_source`;

  try {
    yield call(put, requestURL, { id });
    yield reduxPut(continueFromSourceSuccess());
  } catch (error) {
    yield reduxPut(continueFromSourceError(error));
  }
}

function* auditProgress({ page, choice, trackableId, trackableType }) {
  const params = {
    page,
    choice,
    trackable_id: trackableId,
    trackable_type: trackableType,
  };
  const requestURL = `${API_URL}/audit_progresses`;

  try {
    yield call(post, requestURL, params);
    yield reduxPut(auditProgressSuccess());
  } catch (error) {
    yield reduxPut(auditProgressError(error));
  }
}

function* checkCoughlinEmail({ values }) {
  const requestURL = `${API_URL}/matches/email_coughlin`;

  try {
    yield call(put, requestURL, { ...values });
    yield reduxPut(checkCoughlinEmailSuccess());
  } catch (error) {
    yield reduxPut(checkCoughlinEmailError(error));
  }
}

function* fetchOrsScore({ id, callback }) {
  const requestURL = `${API_URL}/ors_score/${id}`;

  try {
    const score = yield call(get, requestURL);
    yield reduxPut(fetchOrsScoreSuccess(score));
    if (callback) yield call(callback, score);
  } catch (error) {
    yield reduxPut(fetchOrsScoreError(error));
  }
}

function* invitePartner({ email, callback }) {
  const requestURL = `${API_URL}/couples/invite`;
  const params = { recipient_email: email };

  try {
    yield call(post, requestURL, params);
    if (callback) yield call(callback, true, null);
  } catch (error) {
    if (callback) yield call(callback, false, error);
  }
}

function* createGuardians({ values, callback }) {
  const requestURL = `${API_URL}/guardians`;

  try {
    const guardians = yield call(post, requestURL, values);
    yield reduxPut(createGuardiansSuccess(guardians));
    if (callback) yield call(callback, true, null);
  } catch (error) {
    yield reduxPut(createGuardiansError(error));
    if (callback) yield call(callback, false, error);
  }
}

function* fetchGuardians({ userId, callback }) {
  const requestURL = `${API_URL}/guardians/${userId}`;

  try {
    const guardians = yield call(get, requestURL);
    yield reduxPut(fetchGuardiansSuccess(guardians));
    if (callback) yield call(callback, true, null);
  } catch (error) {
    yield reduxPut(fetchGuardiansError(error));
    if (callback) yield call(callback, false, error);
  }
}

function* fetchSpecialities({ locale }) {
  const requestURL = `${API_URL_NO_VERSION}/specialities${
    locale ? `?locale=${locale}` : ''
  }`;
  const results = yield call(get, requestURL);
  yield reduxPut(fetchSpecialitiesSuccess(results));
}
function* fetchDiagnosis({ locale }) {
  const requestURL = `${API_URL_NO_VERSION}/diagnosis_types${
    locale ? `?locale=${locale}` : ''
  }`;
  const results = yield call(get, requestURL);
  yield reduxPut(fetchDiagnosisSuccess(results));
}

// Individual exports for testing
export default function* matchingSaga() {
  yield takeLatest(CREATE_MATCH, createMatch);
  yield takeLatest(FETCH_CURRENT_MATCH, fetchCurrentMatch);
  yield takeLatest(UPDATE_MATCH, updateMatch);
  yield takeLatest(FETCH_MATCH_RESULTS, fetchMatchResults);
  yield takeLatest(FETCH_PAGINATED_MATCH_RESULTS, fetchPaginatedMatchResults);
  yield takeLatest(VERIFY_PHONE_NUMBER, verifyPhoneNumber);
  yield takeLatest(CREATE_PHONE_NUMBER, createPhoneNumber);
  yield takeLatest(FETCH_DIRECT_MATCH, fetchDirectMatch);
  yield takeLatest(CONFIRM_PRACTITIONER_MATCH, confirmPractitionerMatch);
  yield takeLatest(FETCH_PRACTITIONER, fetchPractitioner);
  yield takeLatest(CREATE_MESSAGE, createMessage);
  yield takeLatest(CONTINUE_FROM_SOURCE, continueFromSource);
  yield takeLatest(AUDIT_PROGRESS, auditProgress);
  yield takeLatest(CHECK_COUGHLIN_EMAIL, checkCoughlinEmail);
  yield takeLatest(FETCH_ORS_SCORE, fetchOrsScore);
  yield takeLatest(INVITE_PARTNER, invitePartner);
  yield takeLatest(FETCH_MATCH_REPORT, fetchMatchReport);
  yield takeLatest(CREATE_GUARDIANS, createGuardians);
  yield takeLatest(FETCH_GUARDIANS, fetchGuardians);
  yield takeLatest(FETCH_SPECIALITIES, fetchSpecialities);
  yield takeLatest(FETCH_DIAGNOSIS, fetchDiagnosis);
}
