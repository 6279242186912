/*
 *
 * Matching constants
 *
 */

export const CREATE_MATCH = 'app/Matching/CREATE_MATCH';
export const CREATE_MATCH_SUCCESS = 'app/Matching/CREATE_MATCH_SUCCESS';
export const CREATE_MATCH_ERROR = 'app/Matching/CREATE_MATCH_ERROR';

export const FETCH_CURRENT_MATCH = 'app/Matching/FETCH_CURRENT_MATCH';
export const FETCH_CURRENT_MATCH_SUCCESS =
  'app/Matching/FETCH_CURRENT_MATCH_SUCCESS';
export const FETCH_CURRENT_MATCH_ERROR =
  'app/Matching/FETCH_CURRENT_MATCH_ERROR';

export const UPDATE_MATCH = 'app/Matching/UPDATE_MATCH';
export const UPDATE_MATCH_SUCCESS = 'app/Matching/UPDATE_MATCH_SUCCESS';
export const UPDATE_MATCH_ERROR = 'app/Matching/UPDATE_MATCH_ERROR';

export const FETCH_MATCH_RESULTS = 'app/Matching/FETCH_MATCH_RESULTS';
export const FETCH_MATCH_RESULTS_SUCCESS =
  'app/Matching/FETCH_MATCH_RESULTS_SUCCESS';
export const FETCH_MATCH_RESULTS_ERROR =
  'app/Matching/FETCH_MATCH_RESULTS_ERROR';

export const FETCH_PAGINATED_MATCH_RESULTS =
  'app/Matching/FETCH_PAGINATED_MATCH_RESULTS';
export const FETCH_PAGINATED_MATCH_RESULTS_SUCCESS =
  'app/Matching/FETCH_PAGINATED_MATCH_RESULTS_SUCCESS';
export const FETCH_PAGINATED_MATCH_RESULTS_ERROR =
  'app/Matching/FETCH_PAGINATED_MATCH_RESULTS_ERROR';

export const FETCH_MATCH_REPORT = 'app/Matching/FETCH_MATCH_REPORT';
export const FETCH_MATCH_REPORT_SUCCESS =
  'app/Matching/FETCH_MATCH_REPORT_SUCCESS';
export const FETCH_MATCH_REPORT_ERROR = 'app/Matching/FETCH_MATCH_REPORT_ERROR';

export const CLEAR_MATCH_RESULTS = 'app/Matching/CLEAR_MATCH_RESULTS';
export const CLEAR_DIRECT_MATCH = 'app/Matching/CLEAR_DIRECT_MATCH';
export const CLEAR_CURRENT_MATCH = 'app/Matching/CLEAR_CURRENT_MATCH';
export const SELECT_PRACTITIONER = 'app/Matching/SELECT_PRACTITIONER';

export const VERIFY_PHONE_NUMBER = 'app/Matching/VERIFY_PHONE_NUMBER';
export const VERIFY_PHONE_NUMBER_SUCCESS =
  'app/Matching/VERIFY_PHONE_NUMBER_SUCCESS';
export const VERIFY_PHONE_NUMBER_ERROR =
  'app/Matching/VERIFY_PHONE_NUMBER_ERROR';

export const CREATE_PHONE_NUMBER = 'app/Matching/CREATE_PHONE_NUMBER';
export const CREATE_PHONE_NUMBER_SUCCESS =
  'app/Matching/CREATE_PHONE_NUMBER_SUCCESS';
export const CREATE_PHONE_NUMBER_ERROR =
  'app/Matching/CREATE_PHONE_NUMBER_ERROR';

export const FETCH_DIRECT_MATCH = 'app/Matching/FETCH_DIRECT_MATCH';
export const FETCH_DIRECT_MATCH_SUCCESS =
  'app/Matching/FETCH_DIRECT_MATCH_SUCCESS';
export const FETCH_DIRECT_MATCH_ERROR = 'app/Matching/FETCH_DIRECT_MATCH_ERROR';

export const CONFIRM_PRACTITIONER_MATCH =
  'app/Matching/CONFIRM_PRACTITIONER_MATCH';
export const CONFIRM_PRACTITIONER_MATCH_SUCCESS =
  'app/Matching/CONFIRM_PRACTITIONER_MATCH_SUCCESS';
export const CONFIRM_PRACTITIONER_MATCH_ERROR =
  'app/Matching/CONFIRM_PRACTITIONER_MATCH_ERROR';

export const FETCH_PRACTITIONER = 'app/Matching/FETCH_PRACTITIONER';
export const FETCH_PRACTITIONER_SUCCESS =
  'app/Matching/FETCH_PRACTITIONER_SUCCESS';
export const FETCH_PRACTITIONER_ERROR = 'app/Matching/FETCH_PRACTITIONER_ERROR';

export const CREATE_MESSAGE = 'app/Matching/CREATE_MESSAGE';
export const CREATE_MESSAGE_SUCCESS = 'app/Matching/CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'app/Matching/CREATE_MESSAGE_ERROR';

export const CONTINUE_FROM_SOURCE = 'app/Matching/CONTINUE_FROM_SOURCE';
export const CONTINUE_FROM_SOURCE_SUCCESS =
  'app/Matching/CONTINUE_FROM_SOURCE_SUCCESS';
export const CONTINUE_FROM_SOURCE_ERROR =
  'app/Matching/CONTINUE_FROM_SOURCE_ERROR';

export const AUDIT_PROGRESS = 'app/Matching/AUDIT_PROGRESS';
export const AUDIT_PROGRESS_SUCCESS = 'app/Matching/AUDIT_PROGRESS_SUCCESS';
export const AUDIT_PROGRESS_ERROR = 'app/Matching/AUDIT_PROGRESS_ERROR';

export const CHECK_COUGHLIN_EMAIL = 'app/Matching/CHECK_COUGHLIN_EMAIL';
export const CHECK_COUGHLIN_EMAIL_SUCCESS =
  'app/Matching/CHECK_COUGHLIN_EMAIL_SUCCESS';
export const CHECK_COUGHLIN_EMAIL_ERROR =
  'app/Matching/CHECK_COUGHLIN_EMAIL_ERROR';

export const FETCH_ORS_SCORE = 'app/Matching/FETCH_ORS_SCORE';
export const FETCH_ORS_SCORE_SUCCESS = 'app/Matching/FETCH_ORS_SCORE_SUCCESS';
export const FETCH_ORS_SCORE_ERROR = 'app/Matching/FETCH_ORS_SCORE_ERROR';

export const INVITE_PARTNER = 'app/Matching/INVITE_PARTNER';

export const CREATE_GUARDIANS = 'app/Matching/CREATE_GUARDIANS';
export const CREATE_GUARDIANS_SUCCESS = 'app/Matching/CREATE_GUARDIANS_SUCCESS';
export const CREATE_GUARDIANS_ERROR = 'app/Matching/CREATE_GUARDIANS_ERROR';
export const FETCH_GUARDIANS = 'app/Matching/FETCH_GUARDIANS';
export const FETCH_GUARDIANS_SUCCESS = 'app/Matching/FETCH_GUARDIANS_SUCCESS';
export const FETCH_GUARDIANS_ERROR = 'app/Matching/FETCH_GUARDIANS_ERROR';
export const FETCH_SPECIALITIES = 'app/Matching/FETCH_SPECIALITIES';
export const FETCH_SPECIALITIES_SUCCESS =
  'app/Matching/FETCH_SPECIALITIES_SUCCESS';
export const FETCH_DIAGNOSIS = 'app/Matching/FETCH_DIAGNOSIS';
export const FETCH_DIAGNOSIS_SUCCESS = 'app/Matching/FETCH_DIAGNOSIS_SUCCESS';
